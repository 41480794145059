<template>
    <div class="view-transaction auth-wrapper auth-v1">
        <div class="auth-inner main-container-width">

            <v-row>
                <v-col cols="12"
                       sm="12"
                       md="12"
                       lg="12"
                       class="mx-auto">
                    <v-card flat
                            class="bg-gradient">
                        <app-header></app-header>
                        <div v-if="showAlert"
                             class="mt-3">
                            <alert :message="alertMessage"
                                   :type="alertType"></alert>

                        </div>
                        <v-row class="px-8 pt-5">
                            <v-col cols="12"
                                   lg="4"
                                   class="mt-2">

                                <v-card class="pa-4 transaction">
                                    <PrimaryCard :card="cardDetails"
                                                 :isSupplementarySelected="false"
                                                 :singleCard="true"></PrimaryCard>
                                </v-card>
                            </v-col>
                            <v-col cols="12"
                                   lg="8">
                                <CardDetailTrans :selectedLanguage="selectedLanguage"
                                                 :card="cardDetails"></CardDetailTrans>
                            </v-col>
                        </v-row>


                        <v-card class="security py-4 mx-lg-8 mx-md-2 mx-sm-1 mx-1 mt-3">
                            <v-row class="card-show">
                                <v-col cols="12"
                                       lg="12">
                                    <v-card class="white-card-background mt-6">
                                        <v-row class="d-flex mx-5 align-center"
                                               style="">
                                            <v-col cols="12"
                                                   lg="4"
                                                   md="4"
                                                   sm="12">
                                                <div class="font-weight-regular text-blue"
                                                     style="font-size: 16px;"> {{ $t(`Download your Monthly Statements`) }}
                                                </div>
                                            </v-col>
                                            <v-col cols="12"
                                                   lg="4"
                                                   md="4"
                                                   sm="12">

                                                <v-select v-if="selectedLanguage == 'en'"
                                                          outlined
                                                          flat
                                                          v-model="stmtDate"
                                                          :items="items"
                                                          hide-details
                                                          item-text="dateEn"
                                                          item-value="dateValue"
                                                          class="selection"
                                                          @change="filterTransaction()"
                                                          :placeholder="$t('Select Date')"
                                                          solo>
                                                </v-select>
                                                <v-select dir="rtl"
                                                          v-if="selectedLanguage == 'ar'"
                                                          outlined
                                                          flat
                                                          v-model="stmtDate"
                                                          :items="items"
                                                          hide-details
                                                          item-text="dateAr"
                                                          item-value="dateValue"
                                                          class="selection"
                                                          @change="filterTransaction()"
                                                          :placeholder="$t('Select Date')"
                                                          solo>
                                                </v-select>
                                            </v-col>
                                            <v-col cols="12"
                                                   lg="2"
                                                   md="2"
                                                   sm="12">
                                                <v-btn color="primary"
                                                       x-large
                                                       @click="download"
                                                       block>
                                                    {{ $t('Download') }}
                                                </v-btn>
                                            </v-col>
                                            <v-col cols="12"
                                                   v-if="transactionsArray.length > 0"
                                                   lg="2"
                                                   md="2"
                                                   sm="12">
                                                <v-btn color="primary"
                                                       x-large
                                                       @click="exportToExcel"
                                                       block>
                                                    {{ $t('Download CSV') }}
                                                </v-btn>
                                            </v-col>
                                        </v-row>
                                    </v-card>
                                </v-col>
                            </v-row>
                            <div v-if="transactionsArray.length == 0"
                                 class="text-center text-lg pa-16">
                                {{ $t('No transactions.') }}
                            </div>
                            <div :class="{ 'px-4': selectedLanguage == 'ar' }"
                                 v-for="(tran, index) in transactionsArray"
                                 :key="index">
                                <div style="font-size: 13px;color: #596894;"
                                     class="mt-4 font-weight-semibold">{{ getArabicDate(tran[0], selectedLanguage) }}</div>
                                <Transaction :transactionsArray="tran[1]"
                                             :showBtn="showBtn"></Transaction>
                            </div>
                        </v-card>


                    </v-card>
                </v-col>
            </v-row>
        </div>
    </div>
</template>

<script>
import AppHeader from '../test/AppHeader.vue'
import sharedServices from '@/services/shared-services';
import getRecentActivity from '@/@core/utils/getRecentActivity';
import CardDetailTrans from './CardDetailTrans.vue';
import soapServices from '@/services/soap-services'
import store from '@/store';
import moment from 'moment'
import alert from '@/views/alert.vue';
import { DeviceUUID } from 'device-uuid'
import Transaction from '../transactions/Transaction.vue';
import PrimaryCard from '../card-details/PrimaryCard.vue';
import { stringify } from 'csv-stringify';
import * as XLSX from 'xlsx';
import restErrorMessages from '@/@core/utils/rest-error-messages';

import arabicDate from '@/@core/utils/arabicDate';

export default {
    components: {
        AppHeader,
        PrimaryCard,
        CardDetailTrans,
        Transaction,
        alert
    },

    data: () => ({
        stmtDate: '',
        cardDetails: store.get('card/details'),
        transactionUnfiltered: [],
        showBtn: false,
        transaction: {},
        alertMessage: '',
        alertType: '',
        showAlert: false,
        loaded: false,
        selectedLanguage: 'en',
        searchKeyword: '',
        items: [],
        date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        date1: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        transactionsArray: [],
    }),
    created() 
    {
        store.subscribe((mutation) =>
        {
            if (mutation.type === 'accounts/SET_LANGUAGE')
            {
                this.selectedLanguage = mutation.payload;
            }
        })
    },
    mounted()
    {
        let lang = localStorage.getItem('language');
        if (lang == 'ar')
        {
            this.selectedLanguage = lang;
        }
        else
        {
            this.selectedLanguage = lang;
        }
        getRecentActivity.getStatementHistory().then(histpoyResp =>
        {
            this.items = histpoyResp.dateArray.reverse();
            this.getRecentActivity(true, histpoyResp.stmtDateTime);
        });
    },
    methods: {
        getArabicDate(dateTime, lang)
        {
            return arabicDate.get(dateTime, lang, false)
        },
        onDetails()
        {

        },

        downLoadCSV()
        {
            const headers = ['amount', 'billedAmount'];

            // Create an empty array to hold the CSV rows
            const data = this.transactionsArray
            const csvData = [];
            data.forEach(item =>
            {
                csvData.push([item[0]]);
                csvData.push(headers);
                item[1].forEach(element =>
                {
                    const row = [element.amount, element.billedAmount];
                    csvData.push(row);
                });

            });

            // Convert the CSV data to a string
            stringify(csvData, (err, output) =>
            {
                if (err)
                {
                    console.error(err);
                    return;
                }

                // Create a virtual link element
                const link = document.createElement('a');
                link.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(output);
                link.download = this.stmtDate + '.csv';

                link.click();
            });
        },
        exportToExcel()
        {

            const data = this.transactionsArray
            let headers = [];

            for (let key in this.transactionsArray[0][1][0])
            {
                if (key != 'transaction' && key != 'icon')
                {
                    headers.push(key)
                }
            }


            let csvData = []
            data.forEach(item =>
            {

                csvData.push([item[0]]);

                csvData.push(headers);

                item[1].forEach(element =>
                {
                    let row = [];
                    for (let key in element)
                    {
                        if (key != 'transaction' && key != 'icon')
                        {
                            row.push(element[key]);
                        }
                    }

                    csvData.push(row);
                });
                csvData.push(['']);
            });
            const workbook = XLSX.utils.book_new();

            const worksheet = XLSX.utils.json_to_sheet(csvData);
            const columnWidths = this.calculateColumnWidths(csvData);
            worksheet['!cols'] = columnWidths;
            XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet 1');

            const excelBuffer = XLSX.write(workbook, {
                bookType: 'xlsx',
                type: 'array',
            });

            const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = this.stmtDate + '.xlsx';

            link.click();
        },
        calculateColumnWidths(data)
        {
            const columnWidths = [];

            data.forEach(item =>
            {
                Object.keys(item).forEach((key, index) =>
                {
                    const cellValue = item[key];
                    const cellWidth = cellValue ? cellValue.toString().length * 1 : 0;

                    if (!columnWidths[index] || cellWidth > columnWidths[index].wch)
                    {
                        columnWidths[index] = { wch: cellWidth };
                    }
                });
            });

            return columnWidths;
        },

        download()
        {
            let date = moment(new Date(this.stmtDate)).format('YYMMDD');
            let msgID = `2 ${this.cardDetails.serialNo ? this.cardDetails.serialNo : ''}`;
            msgID = msgID + ' ' + date;

            let obj = {
                idSeed: sharedServices.getIdSeed(),
                sysId: new DeviceUUID().get(),
                msgStp: moment(new Date()).format('DD/MM/yyyy HH:mm:ss'),
                sysAuth: store.get('requestKeys/accessToken'),
                correlationID: Math.floor(1000 + Math.random() * 9000).toString(),
                requestorID: store.get('requestKeys/userName'),
                custSerNo: store.get('requestKeys/ClientCode'),
                accSerno: this.cardDetails.serialNo,
                msgID: msgID
            }

            soapServices.downloadDocument(obj).then(soapResp =>
            {
                if (soapResp.GetMailFileResponse.ErrCode == "000")
                {
                    const base64Data = soapResp.GetMailFileResponse.binFile;
                    function base64ToUint8Array(base64)
                    {
                        const binaryString = window.atob(base64);
                        const length = binaryString.length;
                        const uint8Array = new Uint8Array(length);
                        for (let i = 0; i < length; i++)
                        {
                            uint8Array[i] = binaryString.charCodeAt(i);
                        }
                        return uint8Array;
                    }
                    const uint8Array = base64ToUint8Array(base64Data);
                    const blob = new Blob([uint8Array.buffer], { type: 'application/pdf' });
                    const url = URL.createObjectURL(blob);
                    const link = document.createElement('a');
                    link.href = url;
                    link.download = this.stmtDate + '.pdf';
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                    URL.revokeObjectURL(url);
                }
                else
                {
                    let alertMessage = soapResp.GetMailFileResponse.ErrDesc;
                    soapResp.GetMailFileResponse.ErrCode == "107" ? alertMessage = restErrorMessages.get(soapResp.GetMailFileResponse.ErrCode, 'no_file_message') : alertMessage = soapResp.GetMailFileResponse.ErrDesc
                    this.alertMessage = alertMessage;
                    this.alertType = 'error';
                    this.showAlert = true;
                    setTimeout(() =>
                    {
                        this.showAlert = false;
                    }, 5000)
                }


            })
        },
        getRecentActivity(grouping, dateTime)
        {

            getRecentActivity.getRecentActivity(grouping, dateTime, false).then(response =>
            {
                if (response)
                {
                    this.transactionsArray = response;
                    this.transactionUnfiltered = response;
                    this.loaded = true;
                    this.transactionsArray.forEach(element =>
                    {
                        element.date = moment(element.date).format('DD-MMM-yyyy');
                        element.dateTime = moment(element.dateTime).format('DD-MMM-yyyy');
                        element.postingDate = moment(element.postingDate).format('DD-MMM-yyyy');
                    });
                    this.groupingTrans();
                }
                this.loaded = true;
            });
        },
        filterTransaction()
        {

            this.getRecentActivity(true, this.stmtDate)
        },
        searchTransaction()
        {
            let newArr = []
            for (var j = 0; j < this.transactionUnfiltered.length; j++)
            {
                if (this.transactionUnfiltered[j].match(this.searchKeyword))
                {
                    newArr.push()
                }

            }

        },
        groupingTrans()
        {
            let list = this.transactionUnfiltered
            let dateTransactions = {};
            list.forEach(element =>
            {
                if (!(element.dateSort in dateTransactions))
                {
                    dateTransactions[element.dateSort] = [];
                }
                dateTransactions[element.dateSort].push(element);
            });

            let sortable = [];
            for (var key in dateTransactions)
            {
                sortable.push([key, dateTransactions[key]]);
            }

            sortable.sort(function (a, b)
            {
                return a[1] - b[1];
            });

            // sortable.reverse();
            this.transactionsArray = sortable
        },

        getDateGroup(item)
        {
            let arr = []
            let tranArr = []
            this.transactionsArray.forEach(element =>
            {
                if (item.date == element.date)
                {
                    arr.push({ date: element.date })
                    return
                }
            });
        },
    },

}
</script>

<style lang="scss" scoped>
.white {
    background: white;
}

.v-card {
    box-shadow: none !important;
}

.grey {
    background-color: #D9D9D9;
}

.view-transaction {
    padding-bottom: 0;
    padding-top: 0
}

::v-deep .v-select__selection--comma {
    margin: 7px 4px 7px 0;
    min-height: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    direction: ltr;
}

::v-deep .v-list-item__content {
    align-items: center;
    align-self: center;
    display: flex;
    flex-wrap: wrap;
    flex: 1 1;
    overflow: hidden;
    padding: 12px 0;
    direction: ltr;
}

.card-show {
    padding: 16px 18px 16px 0px;
}

@import '@core/preset/preset/auth.scss';
</style>
